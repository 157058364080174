import { extractValue } from "$components/form-inputs/libs/helpers";
import { isArray, isEmpty, isObject } from "lodash";

import { buildFilterSet } from "../stores/app-context";
import { DEFAULT_SORT } from "../stores/clients";

export const buildFilters = (filters = {}, prefix = "") => {
    const filterOutput = Object.keys(filters)
        .filter((key) => {
            const filterItem = filters[key];

            if (filterItem === undefined || filterItem === null) return false;

            if (isObject(filterItem) && isEmpty(filterItem)) return false;

            if (Array.isArray(filterItem) && filterItem.length === 0) return false;

            if (typeof filterItem === "string" && filterItem.length === 0) return false;

            return true;
        })
        .map((key) => {
            const filterItem = filters[key];
            const keyReplacement = buildFilterSet().find((f) => f.key === key);
            const foundKey = keyReplacement?.apiKey || key;
            if (foundKey === "probability") {
                return Object.keys(filterItem)
                    .map((key) => {
                        return `${prefix}${foundKey}Min=${encodeURIComponent(extractValue(filterItem[key].min) || 0)}&${prefix}${foundKey}Max=${encodeURIComponent(extractValue(filterItem[key].max))}`;
                    })
                    .join("&");
            }
            if (foundKey === "sviScore") {
                const sviMin = filterItem.min ? extractValue(filterItem.min) : 0;
                var sviMax = Number(extractValue(filterItem.max) || 10) + 0.49;
                return `${prefix}${foundKey}Min=${encodeURIComponent(sviMin)}&${prefix}${foundKey}Max=${encodeURIComponent(sviMax)}`;
            }
            if (foundKey === "flags") {
                return filterItem
                    .map((f) => {
                        return `${prefix}${f.value}=true`;
                    })
                    .join("&");
            }
            if (isArray(filterItem)) {
                return filterItem
                    .map((value) => {
                        return `${prefix}${foundKey}=${encodeURIComponent(extractValue(value))}`;
                    })
                    .join("&");
            }
            // Handle min/max objects
            if (filterItem.min || filterItem.max) {
                return `${prefix}${foundKey}Min=${encodeURIComponent(extractValue(filterItem.min) || 0)}&${prefix}${foundKey}Max=${encodeURIComponent(extractValue(filterItem.max))}`;
            }
            return `${prefix}${foundKey}=${encodeURIComponent(extractValue(filterItem))}`;
        })
        .join("&");
    return filterOutput;
};

export const objectToParamString = (data) => {
    // Usage: objectToParamString({ a: '1', b: '2', });
    // Returns: 'a=1&b=2'
    //
    // This also supports arrays:
    // objectToParamString({ a: ['1', '2']});
    // Returns: a=1&a=2
    return Object.keys(data)
        .reduce((arr, key) => {
            if (Array.isArray(data[key])) {
                for (var val of data[key]) {
                    arr.push(`${key}=${val}`);
                }

                return arr;
            }

            if (data[key] !== undefined || typeof data[key] === "boolean") {
                arr.push(`${key}=${data[key]}`);
            }
            return arr;
        }, [])
        .join("&");
};

export const buildSort = (sort = DEFAULT_SORT) => {
    return Object.keys(sort)
        .map((key) => `${key} ${sort[key]}`)
        .join("&sort=");
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const buildResetRedirectPath = (path) => {
    return buildRedirectWithPath("reset", path);
};

export const buildRedirectWithPath = (route, path) => {
    if (!path || path.startsWith("/reset")) {
        return route.startsWith("/") ? route : `/${route}`;
    }

    return `${route.startsWith("/") ? "" : "/"}${route}?path=${encodeURIComponent(path)}`;
};
