import api from "$services/api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CONFIRMATION_TEXT = "I understand that this is a destructive act";

const ResetAdminIndex = () => {
    const navigate = useNavigate();

    const [requestedReset, setRequestedReset] = useState(false);
    const [userConfirmationInput, setUserConfirmationInput] = useState("");
    const [confirmationIsValid, setConfirmationIsValid] = useState(false);

    useEffect(() => {
        setConfirmationIsValid(CONFIRMATION_TEXT === userConfirmationInput);
    }, [userConfirmationInput]);

    const resetApp = async () => {
        const result = await api.delete("/api/admin/reset");
        console.log("result", result);
        if (result === "OK") {
            navigate("/reset");
        }
    };

    return (
        <div>
            <h1>Factory Reset</h1>
            <button onClick={() => setRequestedReset(true)}>Begin Reset</button>
            {requestedReset && (
                <div>
                    <p>Re-Type the sentence below to confirm:</p>
                    <p>{CONFIRMATION_TEXT}</p>
                    <input
                        type="text"
                        value={userConfirmationInput}
                        onChange={(e) => {
                            setUserConfirmationInput(e.currentTarget.value);
                        }}
                    ></input>
                </div>
            )}
            {confirmationIsValid && (
                <div>
                    <button
                        onClick={() => {
                            resetApp();
                        }}
                    >
                        Clicking here will irrevocably reset this app
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResetAdminIndex;
