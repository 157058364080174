import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import api from "$services/api.js";

import FeedbackDialog from "./libs/feedback-dialog.js";
import TableLoader from "$components/table/table-loader.js";

import { fetchPotentialProjects, PotentialProjectsStore, reset, setFilters, setFlagged, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSelectedData, setSort } from "$stores/potentialProjects.js";
import { buildDefaultColumns } from "./libs/columns.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { setHelpContent } from "$stores/help.js";
import { HELP_POTENTIAL_PROJECT_LIST } from "src/pages/help/lib/help-contents.js";
import { dialogConfirm } from "$stores/modal.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const PotentialProjectList = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const { filterMenu, siteConfig } = AppContextStore.useState((a) => {
        return {
            filterMenu: mapByKey(a.filterMenu, "apiKey") || {},
            siteConfig: a.siteConfig,
        };
    });

    useEffect(() => {
        setHelpContent(HELP_POTENTIAL_PROJECT_LIST);
    }, []);

    const { potentialProjects, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, selectedData, sort } = usePaginatedStoreWrapper(PotentialProjectsStore, {
        fetchList: fetchPotentialProjects,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    const rowClick = (row) => {
        navigate(`/project/${row.key}/potential`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: `${getTerm("potentialProject")} List`,
                section: `${getTerm("potentialProject")} List`,
                projectId: row.id,
            }
        );
    };

    const sendFlags = async () => {
        if (selectedData.length <= 0) {
            return false;
        }

        const confirmUpdate = await dialogConfirm({
            data: {},
            Component: FeedbackDialog,
            settings: { type: "confirm" },
        });

        const data = {
            projects: selectedData.map((s) => {
                return {
                    key: s.projectKey,
                    id: s.id,
                };
            }),
            ...confirmUpdate,
        };

        if (confirmUpdate) {
            const result = await api.put("/api/projects/potential/bulk/user-flag", data);
            if (result) {
                // Update Flagged
                setFlagged(selectedData.map((s) => s.id));
                // Clear selected
                setSelectedProjects([]);
            }
        }
    };

    const data = potentialProjects.map(mapToTableData);
    return (
        <div>
            <TableLoader
                allowFilter={true}
                columns={buildDefaultColumns({
                    settings: { visibleColumns: siteConfig.potentialProjectListColumns },
                    updateSelectedList: setSelectedData,
                    filterOptions: filterMenu,
                    globalFilterRanges,
                    sendFlags,
                })}
                containerClass="project-list"
                data={data}
                filters={filters}
                hiddenColumns={hiddenColumns}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                onRowClick={rowClick}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                selectedData={selectedData}
                setPageIndex={setPageIndex}
                setFilters={setFilters}
                setHiddenColumns={setHiddenColumns}
                setPagingInfo={setPagingInfo}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
            />
        </div>
    );
};

export default PotentialProjectList;
